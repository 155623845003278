@import '~application/src/scss/mixins';
@import '~application/src/scss/variables';
@import '~application/src/scss/settings';
@import 'foundation-sites/scss/foundation';

$ribbon-color-default: get-color(alert);

// add new highlighting types here:
$highlightingTypes: (
  1: $dedere-color,
  2: $ribbon-color-default,
  3: get-color(success)
);

:host([type=""]),
:host([type="0"]) {
  display: none !important;
}

:host(:not([version])),
:host([version="1"]) {
  position: absolute;
  left: -10px;
  bottom: 20px;
  width: calc(100% + 20px);
  background: get-color(alert);
  color: $white;
  padding: 0.5rem;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  letter-spacing: 1px;
  text-align: center;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border: 5px solid darken(get-color(alert), 20%);
  }

  &:before {
    bottom: 100%;
    left: 0;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
  }

  &:after {
    top: 100%;
    left: calc(100% - 10px);
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
  }
}

:host([version="2"]) {
  border-bottom: 2px solid get-color(alert);
  width: 100%;
  margin-bottom: 0.5rem;
  display: flex;

  div {
    position: relative;
    background: get-color(alert);
    color: $white;
    padding: 0.25rem 0.5rem;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    letter-spacing: 1px;
    text-align: center;
  }
}

// Coloring
@each $id, $color in $highlightingTypes {
  :host([type="#{$id}"]:not([version])),
  :host([type="#{$id}"][version="1"]) {
    background: $color;

    &:before,
    &:after {
      border-color: darken($color, 20%);
    }
  }

  :host([version="2"][type="#{$id}"]) {
    border-bottom-color: $color;

    &>div {
      background-color: $color;
    }
  }
}